.filterMain{
    font-family: "sans-serif";
    border-right: 0.1rem solid rgba(0,0,0,0.1);
    padding: 15px;
  }
.productMain{
  padding: 5px;
}
.productShowing{
  display: block;
}

@media screen and (min-width: 850px) {
    .filterMain { 
        min-width: 20%;
     } 
    }

@media screen and (max-width: 850px) {
    .productMain { 
        max-width: 50%;
     } 
     .productShowing{
      display: none;
     }
    }