.teamMain{
    max-width: 100%;
    min-width: 250px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.logopara{
    width: 150px;
    margin-right: 20px;
    margin-left: 10px;
} 

.strategies{
    margin-top: 40px;
}

.ourVision{
    margin-top: 40px;
}

@media screen and (min-width: 1200px) {
    .teamMain { display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: "5%";
    margin-top: 40px;
    margin-bottom: 80px;
    margin-left: 0;
    margin-right: 0;
     } 
    .logopara{
        margin-right: 30px;
        width:200px
    }
    .strategies{
        display: flex;
    }
    .ourVision{
        margin-left: 40px;
        margin-top: 0px;
    }
    }
