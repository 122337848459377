.storeImg{
  display:block
}

@media screen and (max-width: 950px) {
   .storeImg{
    display:none
   }
    }

@media screen and (max-width: 450px) {
   .map{
    display:none
   }
   .storeBody{
    max-width: 100%;
    width: 80%;
    border: none;
   }
    }

@media screen and (min-width: 450px) {
   
   .storeBody{
    max-width: 50%;
    width: 40%;
    border-right: 2px solid rgba(104,35,106);
    text-align: center;
   }
    }