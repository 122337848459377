*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 800px) {
  .sideImg { display: none; } 
  }

@media screen and (min-width: 800px) {
  .sidedetails{
    margin-left: 30px;
  }
  }
  .sideImg {
    width: 30%;
  }

  .sidedetails {
    width: 100%;
    margin-bottom: 40px;
  }